import React, { useContext, useEffect } from "react";
import ProductsWrapper from "../components/products/ProductsWrapper";
import "../style/shop.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import disableScroll from "disable-scroll";
import { MainContext } from "../context/MainContext";

const Shop = () => {
  const { selectedState } = useContext(MainContext);
  useEffect(() => {
    selectedState === "default" ? disableScroll.on() : disableScroll.off();
  }, [selectedState]);
  return (
    <>
      <Navbar />
      <ProductsWrapper />
      <Footer />
    </>
  );
};

export default Shop;
