import React, { useContext, useEffect } from "react";
import CategoriesWrapper from "../components/categories/CategoriesWrapper";
import "../style/categories.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import disableScroll from "disable-scroll";
import { MainContext } from "../context/MainContext";

const Categories = () => {
  const { selectedState } = useContext(MainContext);
  useEffect(() => {
    selectedState === "default" ? disableScroll.on() : disableScroll.off();
  }, [selectedState]);

  return (
    <>
      <Navbar />
      <CategoriesWrapper />
      <Footer />
    </>
  );
};

export default Categories;
