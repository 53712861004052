import React, { useContext, useEffect } from "react";
import "../style/about.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { MainContext } from "../context/MainContext";
import disableScroll from "disable-scroll";
import Loader from "../components/Loader";

const About = () => {
  const { selectedState, getAbout, about } = useContext(MainContext);
  useEffect(() => {
    selectedState === "default" ? disableScroll.on() : disableScroll.off();
  }, [selectedState]);
  useEffect(() => {
    getAbout();
  }, []);
  return (
    <>
      <Navbar />
      {about ? (
        <>
          <div className="wrapper aboutWrapper">
            <h1>About Us</h1>
          </div>
          <div
            className="aboutImage"
            style={{
              backgroundImage: `url(${process.env.REACT_APP_PRODUCTION_UPLOADS}${about.image})`,
            }}
          ></div>
          <div
            className="wrapper aboutWrapper"
            dangerouslySetInnerHTML={{ __html: about.description }}
          ></div>
        </>
      ) : (
        <Loader />
      )}
      <Footer />
    </>
  );
};

export default About;
