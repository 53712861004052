import React from "react";
import { Link } from "react-router-dom";

const ProductCard = ({ title, price, image, link }) => {
  return (
    <Link to={link} className="productsCard">
      <div
        className="productCardImage"
        style={{ backgroundImage: `url(${image})` }}
      ></div>

      <div className="productInfos">
        <h1 className="productsCardTitle">{title}</h1>
        <h3 className="productsCardPrice">{price} €</h3>
      </div>
    </Link>
  );
};

export default ProductCard;
