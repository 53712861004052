import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "../style/product.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { FacebookShareButton } from "react-share";
import {
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
} from "react-icons/md";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import { FaFacebookF } from "react-icons/fa";

import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import { MainContext } from "../context/MainContext";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import disableScroll from "disable-scroll";
import Loader from "../components/Loader";

const Product = () => {
  const { addToCart, selectedState, getProduct, product, price, loading } =
    useContext(MainContext);
  useEffect(() => {
    selectedState === "default" ? disableScroll.on() : disableScroll.off();
  }, [selectedState]);
  let { title } = useParams();
  useEffect(() => {
    getProduct(title);
  }, [title]);
  return (
    <div>
      <Navbar />

      {product ? (
        <div>
          <div className="wrapper productWrapper">
            <div className="productSwiper">
              {product.images.length > 0 ? (
                <Swiper
                  navigation={{
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                  }}
                  effect={"fade"}
                  pagination={{
                    dynamicBullets: true,
                    enabled: true,
                    clickable: true,
                    el: ".swiper-pagination",
                  }}
                  loop={true}
                  autoplay={{
                    delay: 3000,
                  }}
                  modules={[Navigation, Pagination, Autoplay, EffectFade]}
                  className="productSlider"
                >
                  {product.images.map((image, key) => {
                    return (
                      <SwiperSlide
                        className="slide"
                        style={{
                          backgroundImage: `url(${process.env.REACT_APP_PRODUCTION_UPLOADS}${image})`,
                        }}
                        key={key}
                      ></SwiperSlide>
                    );
                  })}
                  <div className="swiper-button-prev">
                    <MdKeyboardDoubleArrowLeft color="#C3854C" />
                  </div>
                  <div className="swiper-button-next">
                    <MdKeyboardDoubleArrowRight color="#C3854C" size={10} />
                  </div>
                  <div className="swiper-pagination">
                    {product.images.map((e, i) => {
                      return (
                        <div className="custom-pagination-bullet" key={i}></div>
                      );
                    })}
                  </div>
                </Swiper>
              ) : (
                <></>
              )}
            </div>
            <div className="productInfo">
              <h1>{product.title}</h1>
              <h3>{product.price + price}€</h3>
              <button
                className="hoverable"
                onClick={() => {
                  addToCart(title);
                }}
              >
                ADD TO CART
              </button>
              <p>{product.description}</p>
              <h4>Size</h4>
              <p>{product.dimensions}</p>
              <FacebookShareButton
                url={`https://dittoceramics.com/products/${title}`}
                content={product.description}
                className="facebookShareBtn"
              >
                <FaFacebookF color="white" /> Share
              </FacebookShareButton>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <Footer />
    </div>
  );
};

export default Product;
