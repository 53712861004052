import React, { useContext, useEffect } from "react";
import Banner from "../components/homepage/Banner";
import "../style/homepage.css";
import SecondSection from "../components/homepage/SecondSection";
import Newsletter from "../components/Newsletter";
import ThirdSection from "../components/homepage/ThirdSection";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { MainContext } from "../context/MainContext";
import disableScroll from "disable-scroll";
import Loader from "../components/Loader";

const Homepage = () => {
  const { selectedState, homepage } = useContext(MainContext);
  useEffect(() => {
    selectedState === "default" ? disableScroll.on() : disableScroll.off();
  }, [selectedState]);
  return (
    <>
      {homepage ? (
        <>
          <Navbar />
          <Banner images={homepage.sliders} />
          <SecondSection secondBanner={homepage.secondBanner} />
          <ThirdSection thirdBanner={homepage.thirdBanner} />
          <Newsletter />
          <Footer />
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Homepage;
