import React, { createContext, useState, useEffect } from "react";
// import axios from "axios";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { countries } from "../helpers/countries";

const MainContext = createContext();

const MainProvider = ({ children }) => {
  const navigate = useNavigate();
  const [cart, setCart] = useState([]);
  const [cartProducts, setCartProducts] = useState([]);
  const [states, setStates] = useState(countries);
  const [selectedState, setSelectedSate] = useState(`default`);
  const [homepage, setHomepage] = useState({});
  const [products, setProducts] = useState();
  const [product, setProduct] = useState();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCat, setSelectedCat] = useState(`All`);
  const [selectedSubcat, setSelectedSubcat] = useState(`All`);
  const [selectedSort, setSelectedSort] = useState(`a-z`);
  const [myPrices, setMyPrices] = useState([]);
  const [price, setPrice] = useState();
  const [about, setAbout] = useState({});
  const [refund, setRefund] = useState({});
  const [terms, setTerms] = useState({});
  const [privacy, setPrivacy] = useState({});

  const [cca2, setCca2] = useState(`XK`);

  useEffect(() => {
    const savedCart = Cookies.get(`cart`) || [];
    if (savedCart.length !== 0) {
      setCart(JSON.parse(savedCart));
    }
    getStates();
    getHomepage();
    getProducts();
    prices();
  }, []);
  const toastifyData = {
    position: `top-center`,
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
    zIndex: 999999,

    theme: `dark`,
  };

  useEffect(() => {
    localStorage.setItem(
      `price`,
      myPrices.filter((e) => e.name === selectedState)[0]
        ? myPrices.filter((e) => e.name === selectedState)[0].price
        : ``
    );
  }, [selectedState, myPrices]);

  useEffect(() => {
    setPrice(Number(localStorage.getItem(`price`)));
  }, [selectedState]);

  useEffect(() => {
    Cookies.set(`cart`, JSON.stringify(cart));
    setCartProducts(
      products ? products.filter((product) => cart.includes(product._id)) : []
    );
  }, [cart, products]);

  const addToCart = (product) => {
    const isProductInCart = cart.includes(product);

    if (!isProductInCart) {
      setCart([...cart, product]);
      navigate("/shop");
      toast.success(`Product has been added to cart.`, toastifyData);
    } else {
      navigate("/shop");
      toast.warning(`Product ${product} is already in the cart.`, toastifyData);
    }
  };

  const removeFromCart = (product) => {
    const updatedCart = cart.filter((item) => item !== product);
    setCart(updatedCart);
    toast.success(
      `Product ${product} has been removed from the cart.`,
      toastifyData
    );
  };

  const getStates = async () => {
    axios
      .get(`https://restcountries.com/v3.1/all`)
      .then((response) => {
        setStates(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleOptionChange = (event) => {
    setSelectedSate(event.value);
    setCca2(states.filter((e) => e.name.common.includes(event.value))[0].cca2);
  };

  const getHomepage = async () => {
    await axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}homepage`)
      .then((res) => {
        setHomepage(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getProducts = async () => {
    await axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}products`)
      .then((res) => {
        setProducts(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getProduct = async (id) => {
    setProduct({
      images: [],
      title: ``,
      description: ``,
      dimensions: ``,
      show: true,
      price: 0,
      category: ``,
      subcategory: ``,
    });
    await axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}products/${id}`)
      .then((res) => {
        setProduct(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCategories = async () => {
    await axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}categories`)
      .then((res) => {
        setCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getSubcategories = async () => {
    await axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}subcategory`)
      .then((res) => {
        setSubCategories(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const prices = async () => {
    await axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}country`)
      .then((res) => {
        setMyPrices(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAbout = async () => {
    await axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}about`)
      .then((res) => {
        setAbout(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getRefund = async () => {
    await axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}refund`)
      .then((res) => {
        setRefund(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getPrivacy = async () => {
    await axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}privacy`)
      .then((res) => {
        setPrivacy(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getTerms = async () => {
    await axios
      .get(`${process.env.REACT_APP_PRODUCTION_API_URL}terms`)
      .then((res) => {
        setTerms(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MainContext.Provider
      value={{
        addToCart,
        cart,
        cartProducts,
        products,
        setCartProducts,
        removeFromCart,
        selectedState,
        homepage,
        getProduct,
        product,
        getCategories,
        categories,
        getSubcategories,
        subCategories,
        selectedCat,
        setSelectedCat,
        selectedSubcat,
        setSelectedSubcat,
        selectedSort,
        setSelectedSort,
        getProducts,
        price,
        getAbout,
        about,
        getRefund,
        refund,
        privacy,
        terms,
        getPrivacy,
        getTerms,
        cca2,
        toastifyData,
      }}
    >
      {states ? (
        <>
          {selectedState === `default` ? (
            <div className="statesOverlay">
              <h1>Please select your state before you continue shoping</h1>

              <Select
                onChange={handleOptionChange}
                defaultValue={{
                  value: "default",
                  label: "Select your country",
                }}
                className="selectState"
                options={states
                  .filter(
                    (state) =>
                      ![
                        "AS",
                        "CX",
                        "CC",
                        "CU",
                        "HM",
                        "IR",
                        "KP",
                        "MH",
                        "FM",
                        "NF",
                        "MP",
                        "PW",
                        "SD",
                        "SY",
                        "UM",
                        "VI",
                      ].some((code) => state.cca2.includes(code))
                  )
                  .map((state) => {
                    return {
                      value: state.name.common,
                      label: state.name.common,
                    };
                  })}
              />
            </div>
          ) : (
            <></>
          )}
          {children}
        </>
      ) : (
        <Loader />
      )}
      <ToastContainer />
    </MainContext.Provider>
  );
};

export { MainContext, MainProvider };
