import React, { useContext, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { MainContext } from "../context/MainContext";
import disableScroll from "disable-scroll";
import Loader from "../components/Loader";

const Refund = () => {
  const { getRefund, refund, selectedState } = useContext(MainContext);
  useEffect(() => {
    getRefund();
  }, []);
  useEffect(() => {
    selectedState === "default" ? disableScroll.on() : disableScroll.off();
  }, [selectedState]);
  return (
    <>
      <Navbar />
      {refund ? (
        <div
          className="wrapper infoWrapper"
          dangerouslySetInnerHTML={{ __html: refund.content }}
        ></div>
      ) : (
        <Loader />
      )}
      <Footer />
      <Footer />
    </>
  );
};

export default Refund;
