import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CategoryImage from "../components/categories/CategoryImage";
import ProductCard from "../components/products/ProductCard";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import disableScroll from "disable-scroll";
import { MainContext } from "../context/MainContext";
import Loader from "../components/Loader";

const Category = () => {
  const {
    selectedState,
    getCategories,
    categories,
    getProducts,
    products,
    price,
  } = useContext(MainContext);
  useEffect(() => {
    selectedState === "default" ? disableScroll.on() : disableScroll.off();
  }, [selectedState]);
  let { title } = useParams();

  const [category, setCategory] = useState();

  useEffect(() => {
    getCategories();
    getProducts();
  }, []);

  useEffect(() => {
    setCategory(categories.filter((cat) => cat._id === title)[0]);
  }, [categories]);

  return (
    <>
      <Navbar />
      {category ? (
        <CategoryImage
          image={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${category.image}`}
          title={category.name}
        />
      ) : (
        <></>
      )}
      <div className="wrapper productsWrapper">
        <h1 className="productsTitle">Products</h1>
        <div className="productsGrid">
          {products ? (
            products
              .filter((filter) => filter.category._id === title)
              .map((prod, i) => {
                return (
                  <ProductCard
                    key={i}
                    image={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${prod.images[0]}`}
                    price={prod.price + price}
                    title={prod.title}
                    link={`/products/${prod._id}`}
                  />
                );
              })
          ) : (
            <Loader />
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Category;
