import React, { useContext, useEffect } from "react";
import CategoriesCard from "./CategoriesCard";
import { MainContext } from "../../context/MainContext";
import Loader from "../Loader";

const CategoriesWrapper = () => {
  const { categories, subCategories, getCategories, getSubcategories } =
    useContext(MainContext);
  const image =
    "https://cepedaceramics.com/cdn/shop/collections/IMG_0511_540x.jpg?v=1543354973";
  const title = "Category";

  useEffect(() => {
    getCategories();
    getSubcategories();
  }, []);
  return (
    <div className="wrapper categoriesWrapper">
      <h1 className="categoriesTitle">Categories</h1>
      <div className="categoriesGrid">
        {categories ? (
          categories.map((cat, i) => {
            return (
              <CategoriesCard
                image={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${cat.image}`}
                title={cat.name}
                link={`/categories/${cat._id}`}
                key={i}
              />
            );
          })
        ) : (
          <Loader />
        )}
        {/* {subCategories ? (
          subCategories
            .filter((s) => s._id !== "6519f041bc02339870230108")
            .map((subCat, i) => {
              return (
                <CategoriesCard
                  image={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${subCat.image}`}
                  title={subCat.name}
                  link={`/categories/`}
                  key={i}
                />
              );
            })
        ) : (
          <></>
        )} */}
      </div>
    </div>
  );
};

export default CategoriesWrapper;
