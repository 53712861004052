import React, { useContext, useEffect } from "react";
import { MainContext } from "../../context/MainContext";

const Filters = () => {
  const {
    getCategories,
    getSubcategories,
    categories,
    subCategories,
    selectedCat,
    setSelectedCat,
  } = useContext(MainContext);

  useEffect(() => {
    getCategories();
    getSubcategories();
  }, []);

  const handleOptionSelect = (selectedOption) => {
    setSelectedCat(selectedOption);
  };

  return (
    <>
      {categories && subCategories ? (
        <div className="filtersWrapper">
          <div className="categoriesFilter">
            <label htmlFor="categoryInput">Filters: </label>
            <select
              value={selectedCat}
              onChange={(e) => handleOptionSelect(e.target.value)}
              id="categoryInput"
            >
              <option value="All">All</option>

              {categories.map((category, index) => {
                return (
                  <optgroup label={category.name} key={`${category}${index}`}>
                    {subCategories.filter(
                      (subcategory) => subcategory.category === category._id
                    ).length > 0 ? (
                      subCategories
                        .filter(
                          (subcategory) => subcategory.category === category._id
                        )
                        .map((subCategory, index) => (
                          <option key={index} value={subCategory._id}>
                            {subCategory.name}
                          </option>
                        ))
                    ) : (
                      <option key={index} value={category._id}>
                        {category.name}
                      </option>
                    )}
                  </optgroup>
                );
              })}
            </select>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Filters;
