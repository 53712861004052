import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "animate.css";
import Homepage from "./pages/Homepage";
import Shop from "./pages/Shop";
import Categories from "./pages/Categories";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Cart from "./pages/Cart";
import Product from "./pages/Product";
import Category from "./pages/Category";
import { MainProvider } from "./context/MainContext";
import Refund from "./pages/Refund";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import ScrollToTop from "./components/ScrollToTop";
import "react-toastify/dist/ReactToastify.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <MainProvider children={<ScrollToTop children={<Homepage />} />} />
    ),
  },
  {
    path: "/shop",
    element: <MainProvider children={<ScrollToTop children={<Shop />} />} />,
  },
  {
    path: "/categories",
    element: (
      <MainProvider children={<ScrollToTop children={<Categories />} />} />
    ),
  },
  {
    path: "/about",
    element: <MainProvider children={<ScrollToTop children={<About />} />} />,
  },
  {
    path: "/contact",
    element: <MainProvider children={<ScrollToTop children={<Contact />} />} />,
  },

  {
    path: "/cart",
    element: <MainProvider children={<ScrollToTop children={<Cart />} />} />,
  },
  {
    path: "/products/:title",
    element: <MainProvider children={<ScrollToTop children={<Product />} />} />,
  },
  {
    path: "/categories/:title",
    element: (
      <MainProvider children={<ScrollToTop children={<Category />} />} />
    ),
  },
  {
    path: "/refund-policy",
    element: <MainProvider children={<ScrollToTop children={<Refund />} />} />,
  },
  {
    path: "/privacy-policy",
    element: <MainProvider children={<ScrollToTop children={<Privacy />} />} />,
  },
  {
    path: "/terms-of-service",
    element: <MainProvider children={<ScrollToTop children={<Terms />} />} />,
  },
]);
function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
