import React from "react";

const SecondSection = ({ secondBanner }) => {
  return (
    <>
      {secondBanner ? (
        <div className="wrapper hSsectionWrapper">
          <img
            src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}homepage/${secondBanner.image}`}
            alt="Vase"
          />

          <div className="hSsectionText">
            <h1>{secondBanner.header}</h1>
            <p>{secondBanner.description}</p>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default SecondSection;
