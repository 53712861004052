import React, { useContext, useState } from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { MainContext } from "../context/MainContext";

const Newsletter = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const { toastifyData } = useContext(MainContext);
  const [sent, setSent] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    setSent(true);
    e.preventDefault();

    // Replace 'your_service_id', 'your_template_id', and 'your_user_id' with your EmailJS details
    emailjs
      .send(
        "service_s73iyfw",
        "template_lzm8kia",
        formData,
        "42Pa9-jGHrAZ31Uup"
      )
      .then((response) => {
        setSent(false);

        toast.success(
          `We have accepted your message and we will contact you shortly`,
          toastifyData
        );
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
        setSent(false);

        // You can handle errors here (e.g., show an error message)
      });
  };
  return (
    <div className="wrapper newsletterWrapper">
      <h1>SUBSCRIBE TO OUR NEWSLETTER</h1>
      <p>Sign up to receive news about future collections.</p>
      <form className="newsletterInput" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email address"
          className="emailInput"
          name="email"
          onChange={handleChange}
          required
        />
        <button className="newsletterBtn" type="submit" disabled={sent}>
          SUBSCRIBE
        </button>
      </form>
    </div>
  );
};

export default Newsletter;
