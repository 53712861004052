import React, { useContext, useEffect, useState } from "react";
import "../style/navbar.css";
import Logo from "../assets/logo.svg";
import { Link, useLocation } from "react-router-dom";
import { MdSearch, MdShoppingCart, MdMenu, MdClose } from "react-icons/md";
import disableScroll from "disable-scroll";
import Search from "./Search";
import { MainContext } from "../context/MainContext";

const Navbar = () => {
  const pageLocation = useLocation();
  const [location, setLocation] = useState("/");
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const { cartProducts } = useContext(MainContext);
  useEffect(() => {
    setLocation(pageLocation.pathname);
  }, [pageLocation]);
  useEffect(() => {
    open ? disableScroll.on() : disableScroll.off();
  }, [open]);
  const handleMenu = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="wrapper navbarWrapper">
        <Link to="/" id="navbarLogo">
          <img src={Logo} alt="logo" />
        </Link>
        <div className="navLinks">
          <Link
            to="/"
            className={location === "/" ? "hoverable location" : "hoverable"}
          >
            Home
          </Link>
          <Link
            to="/shop"
            className={
              location === "/shop" ? "hoverable location" : "hoverable"
            }
          >
            Shop
          </Link>
          <Link
            to="/categories"
            className={
              location === "/categories" ? "hoverable location" : "hoverable"
            }
          >
            Categories
          </Link>
          <Link
            to="/about"
            className={
              location === "/about" ? "hoverable location" : "hoverable"
            }
          >
            About
          </Link>
          <Link
            to="/contact"
            className={
              location === "/contact" ? "hoverable location" : "hoverable"
            }
          >
            Contact
          </Link>
        </div>
        <div className="navIcons">
          <MdSearch
            color="var(--primary)"
            size={30}
            className="hoverable"
            onClick={() => {
              setSearch(!search);
            }}
          />

          <Link to="/cart" className="cartLink">
            <MdShoppingCart
              color="var(--primary)"
              size={30}
              className="hoverable"
            />
            {cartProducts.length > 0 ? (
              <div className="countLabel">{cartProducts.length}</div>
            ) : (
              <></>
            )}
          </Link>

          <MdMenu
            color="var(--primary)"
            size={30}
            className="hoverable mobileIcon"
            onClick={() => {
              setOpen(true);
            }}
          />
        </div>
        {open ? (
          <div className="mobileNavbar animate__animated animate__fadeInDown">
            <div className="closeIcon">
              <MdClose
                color="white"
                size={30}
                className="hoverable"
                onClick={handleMenu}
              />
            </div>
            <Link to="/" id="mobileNavbarLogo">
              <img src={Logo} alt="logo" />
            </Link>
            <div className="mobileNavLinks">
              <Link
                to="/"
                className={
                  location === "/" ? "hoverable location" : "hoverable"
                }
                onClick={handleMenu}
              >
                Home
              </Link>
              <Link
                to="/shop"
                className={
                  location === "/shop" ? "hoverable location" : "hoverable"
                }
                onClick={handleMenu}
              >
                Shop
              </Link>
              <Link
                to="/categories"
                className={
                  location === "/categories"
                    ? "hoverable location"
                    : "hoverable"
                }
                onClick={handleMenu}
              >
                Categories
              </Link>
              <Link
                to="/about"
                className={
                  location === "/about" ? "hoverable location" : "hoverable"
                }
                onClick={handleMenu}
              >
                About
              </Link>
              <Link
                to="/contact"
                className={
                  location === "/contact" ? "hoverable location" : "hoverable"
                }
                onClick={handleMenu}
              >
                Contact
              </Link>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {search ? <Search setSearch={setSearch} /> : <></>}
    </>
  );
};

export default Navbar;
