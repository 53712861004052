import React, { useContext, useEffect, useState } from "react";
import "../style/search.css";
import { MdClose } from "react-icons/md";
import SearchProducts from "./products/SearchProducts";
import { MainContext } from "../context/MainContext";

const Search = ({ setSearch }) => {
  const { products, getProducts } = useContext(MainContext);
  const [searchProd, setSearchProd] = useState([]);
  useEffect(() => {
    getProducts();
  }, []);
  return (
    <>
      {products.length > 0 ? (
        <div
          className={"searchWrapper"}
          onMouseLeave={() => {
            setSearch(false);
          }}
          style={
            searchProd.length > 0
              ? { height: "50vh" }
              : { height: "fit-content" }
          }
        >
          <MdClose
            size={25}
            className="searchCloseIcon"
            onClick={() => {
              setSearch(false);
            }}
          />

          <div className="contactInputs">
            <label htmlFor="search">Search</label>
            <input
              type="text"
              id="search"
              name="search"
              onChange={(e) => {
                if (e.target.value === "") {
                  setSearchProd([]);
                } else {
                  setSearchProd(
                    products.filter((product) =>
                      product.title
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                    )
                  );
                }
              }}
            />
          </div>
          {searchProd.length > 0 ? (
            <div className="searchProducts">
              {searchProd.map((prod, i) => {
                return (
                  <SearchProducts
                    title={prod.title}
                    id={prod._id}
                    setSearch={setSearch}
                    image={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${prod.images[0]}`}
                    key={i}
                  />
                );
              })}
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default Search;
