import React from "react";

const CategoryImage = ({ title, image }) => {
  return (
    <div className="categoryImage" style={{ backgroundImage: `url(${image})` }}>
      <div>
        <h1>{title}</h1>
      </div>
    </div>
  );
};

export default CategoryImage;
