import React from "react";
import { Link } from "react-router-dom";
import Loader from "../Loader";

const ThirdSection = ({ thirdBanner }) => {
  return (
    <>
      {thirdBanner ? (
        <div
          className="hTsectionWrapper"
          style={{
            backgroundImage: `url(${process.env.REACT_APP_PRODUCTION_UPLOADS}homepage/${thirdBanner.image})`,
          }}
        >
          <h1>{thirdBanner.header}</h1>
          <h3>{thirdBanner.paragraph}</h3>
          <Link to="/shop">SHOP</Link>
        </div>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
};

export default ThirdSection;
