import React from "react";
import { Link } from "react-router-dom";

const CategoriesCard = ({ image, title, link }) => {
  return (
    <Link
      to={link}
      className="categoriesCard"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div>
        <h1 className="categoriesCardTitle">{title}</h1>
      </div>
    </Link>
  );
};

export default CategoriesCard;
