import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  MdKeyboardDoubleArrowRight,
  MdKeyboardDoubleArrowLeft,
} from "react-icons/md";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import "swiper/css/pagination";

// import required modules
import { Navigation, Pagination, Autoplay, EffectFade } from "swiper/modules";
import Loader from "../Loader";
const Banner = ({ images }) => {
  // const images = [
  //   "https://images.unsplash.com/photo-1597696929736-6d13bed8e6a8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8dXJufGVufDB8fDB8fHww&w=1000&q=80",
  //   "https://media.istockphoto.com/id/1272309995/photo/handmade-minimal-ceramics.jpg?s=170667a&w=0&k=20&c=IWcDngcxB98ukiKASk_17ZpzwDPD8I2cFIi5RcJe3bc=",
  //   "https://www.amoca.org/wp-content/uploads/2021/01/AMOCA-Zoom-MettlachVase-1.jpg",
  // ];
  return (
    <>
      {images ? (
        <Swiper
          navigation={{
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
          }}
          effect={"fade"}
          pagination={{
            dynamicBullets: true,
            enabled: true,
            clickable: true,
            el: ".swiper-pagination",
          }}
          loop={true}
          autoplay={{
            delay: 3000,
          }}
          modules={[Navigation, Pagination, Autoplay, EffectFade]}
          className="homepageSwiper"
        >
          {images ? (
            images.map((image, key) => {
              return (
                <SwiperSlide
                  className="slide"
                  style={{
                    backgroundImage: `url(${process.env.REACT_APP_PRODUCTION_UPLOADS}homepage/${image})`,
                  }}
                  key={key}
                ></SwiperSlide>
              );
            })
          ) : (
            <SwiperSlide className="slide"></SwiperSlide>
          )}
          <div className="swiper-button-prev">
            <MdKeyboardDoubleArrowLeft color="var(--primary)" size={10} />
          </div>
          <div className="swiper-button-next">
            <MdKeyboardDoubleArrowRight color="var(--primary)" size={10} />
          </div>
          <div className="swiper-pagination">
            {images.map((e, i) => {
              return <div className="custom-pagination-bullet" key={i}></div>;
            })}
          </div>
        </Swiper>
      ) : (
        <>
          <Loader />
        </>
      )}
    </>
  );
};

export default Banner;
