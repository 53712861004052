import React, { useContext, useEffect } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { MainContext } from "../context/MainContext";
import disableScroll from "disable-scroll";
import Loader from "../components/Loader";
const Privacy = () => {
  const { getPrivacy, privacy, selectedState } = useContext(MainContext);
  useEffect(() => {
    selectedState === "default" ? disableScroll.on() : disableScroll.off();
  }, [selectedState]);
  useEffect(() => {
    getPrivacy();
  }, []);
  return (
    <>
      <Navbar />
      {privacy ? (
        <div
          className="wrapper infoWrapper"
          dangerouslySetInnerHTML={{ __html: privacy.content }}
        ></div>
      ) : (
        <Loader />
      )}
      <Footer />
    </>
  );
};

export default Privacy;
