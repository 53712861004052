import React, { useContext, useEffect, useState } from "react";
import "../style/contact.css";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import disableScroll from "disable-scroll";
import { MainContext } from "../context/MainContext";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";

const Contact = () => {
  const { selectedState, toastifyData } = useContext(MainContext);
  const [sent, setSent] = useState(false);
  useEffect(() => {
    selectedState === "default" ? disableScroll.on() : disableScroll.off();
  }, [selectedState]);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    setSent(true);
    e.preventDefault();

    // Replace 'your_service_id', 'your_template_id', and 'your_user_id' with your EmailJS details
    emailjs
      .send(
        "service_s73iyfw",
        "template_hmc3sla",
        formData,
        "42Pa9-jGHrAZ31Uup"
      )
      .then((response) => {
        setSent(false);

        toast.success(
          `We have accepted your message and we will contact you shortly`,
          toastifyData
        );
      })
      .catch((error) => {
        console.error("Email sending failed:", error);
        setSent(false);

        // You can handle errors here (e.g., show an error message)
      });
  };
  return (
    <>
      <Navbar />
      <div className="wrapper contactWrapper">
        <h1>Contact</h1>
        <p>
          Connect with the talented artisan behind Ditto Ceramics, the creator
          of exquisite handmade ceramics. They're ready to bring the magic of
          handcrafted artistry to your world.
        </p>
        <div className="contact">
          <form onSubmit={handleSubmit}>
            <div className="contactInputs">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="contactInputs">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="contactInputs">
              <label htmlFor="phone">Phone Number</label>
              <input
                type="text"
                id="phone"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                required
              />
            </div>
            <div className="contactInputs">
              <label htmlFor="message">Message</label>
              <textarea
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>
            <button className="sendBtn" type="submit" disabled={sent}>
              SEND
            </button>
          </form>
          <div className="contactInfo">
            <div className="contactInputs contactInfos">
              <p>address</p>
              <h3>
                Prishtinë, <br></br>Republic of Kosova
              </h3>
            </div>
            <div className="contactInputs contactInfos">
              <p>phone</p>
              <h3>+38344403404</h3>
            </div>
            <div className="contactInputs contactInfos">
              <p>email</p>
              <h3>ditto.handmadeceramics@gmail.com</h3>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
