import React, { useContext, useEffect } from "react";
import { MainContext } from "../context/MainContext";
import "../style/cart.css";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import disableScroll from "disable-scroll";
import axios from "axios";

const Cart = () => {
  const { cartProducts, removeFromCart, selectedState, price, cca2 } =
    useContext(MainContext);
  useEffect(() => {
    selectedState === "default" ? disableScroll.on() : disableScroll.off();
  }, [selectedState]);
  const totalPrice = cartProducts.reduce(
    (accumulator, product) => accumulator + product.price + price,
    0
  );

  const handleCheckout = (cartItems) => {
    axios
      .post(
        `${process.env.REACT_APP_PRODUCTION_API_URL}stripe/create-checkout-session`,
        {
          cartItems,
          cca2,
          price,
        }
      )
      .then((res) => {
        if (res.data.url) {
          window.location.href = res.data.url;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Navbar />
      <div className="wrapper cartWrapper">
        <h1>Your cart</h1>
        <div className="cartProducts">
          {cartProducts.length > 0 ? (
            <>
              <div className="cartRow cartRow1">
                <h3>Product</h3>
                <h3>Price</h3>
              </div>
              {cartProducts.map((product, i) => {
                return (
                  <div className="cartRow" key={i}>
                    <Link
                      to={`/products/${product._id}`}
                      className="productCart"
                    >
                      <img
                        src={`${process.env.REACT_APP_PRODUCTION_UPLOADS}${product.images[0]}`}
                        alt="productImage"
                      />
                      <h4>{product.title}</h4>
                    </Link>
                    <h5>{product.price + price} €</h5>
                    <MdOutlineRemoveShoppingCart
                      className="removeBtn hoverable"
                      size={25}
                      onClick={() => {
                        removeFromCart(product._id);
                      }}
                    />
                  </div>
                );
              })}
              <div className="cartRow">
                <h3>Total</h3>
                <h3>{totalPrice} €</h3>
              </div>
              <div className="cartRow buttonRow">
                <button
                  className="hoverable"
                  onClick={() => handleCheckout(cartProducts)}
                >
                  Proceed to checkout
                </button>
              </div>
            </>
          ) : (
            <div>
              <h3 className="emptyCart">Your cart is empty</h3>
              <Link to="/shop" className="shopLink hoverable">
                Shop
              </Link>
            </div>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Cart;
