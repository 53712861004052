import React from "react";
import { Link } from "react-router-dom";

const SearchProducts = ({ title, setSearch, image, id }) => {
  return (
    <Link
      to={`/products/${id}`}
      className="searchProduct"
      onClick={() => {
        setSearch(false);
      }}
    >
      <img src={image} alt="productImage" />
      <h1>{title}</h1>
    </Link>
  );
};

export default SearchProducts;
