import React from "react";
import "../style/footer.css";
import Logo from "../assets/logo.svg";
import { Link } from "react-router-dom";
import { LuInstagram, LuFacebook } from "react-icons/lu";
import ApplePay from "../assets/applePay.svg";
import GooglePay from "../assets/googlePay.svg";
import Mastercard from "../assets/mastercard.svg";
import Visa from "../assets/visa.svg";

const Footer = () => {
  const year = new Date().getFullYear();
  return (
    <div className="wrapper footerWrapper">
      <div className="fMainSection">
        <img src={Logo} alt="logo" className="footerLogo" />
        <div className="footerLinks">
          <h3>Quick Links</h3>

          <Link to="/contact" className="hoverable">
            Contact
          </Link>
          <Link to="/refund-policy" className="hoverable">
            Refund Policy
          </Link>
          <Link to="/privacy-policy" className="hoverable">
            Privacy Policy
          </Link>
          <Link to="/terms-of-service" className="hoverable">
            Terms of Service
          </Link>
        </div>
      </div>
      <div className="socialCards">
        <div className="row">
          <a
            href="https://www.facebook.com/profile.php?id=100076081189846"
            target="_blank"
            rel="noreferrer"
            className="hoverable"
          >
            <LuFacebook size={25} color="var(--primary)" /> Facebook
          </a>
          <a
            href="https://www.instagram.com/ditto.ceramics/"
            target="_blank"
            rel="noreferrer"
            id="instagramLink"
            className="hoverable"
          >
            <LuInstagram size={25} color="var(--primary)" /> Instagram
          </a>
        </div>
        <div className="row">
          <img src={Visa} alt="payment" className="payment hoverable" />
          <img src={Mastercard} alt="payment" className="payment hoverable" />
          <img src={GooglePay} alt="payment" className="payment hoverable" />
          <img src={ApplePay} alt="payment" className="payment hoverable" />
        </div>
      </div>
      <div className="copyright">
        <p>
          © {year}, Ditto Ceramics.<br className="break"></br> Powered by
          <a
            href="https://www.pikabootech.com"
            className="hoverable"
            target="_blank"
            rel="noreferrer"
          >
            {" "}
            Pikaboo Tech
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
